<template>
  <div>
    <ts-page-title
      :title="$t('driverProfile.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('driverProfile.pageTitle'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper>
        <div class="tw-flex tw-justify-between tw-items-center">
          <div>
            <a-button type="primary" @click="addNew">
              {{ $t("addNew") }}
            </a-button>
          </div>
          <div class="tw-space-x-3">
            <a-radio-group v-model="isInternalDriver" @change="fetchData()">
              <a-radio-button :value="1">
                {{ $t("driverProfile.internal") }}
              </a-radio-button>
              <a-radio-button :value="0">
                {{ $t("driverProfile.external") }}
              </a-radio-button>
            </a-radio-group>
            <a-radio-group v-model="isActive" @change="fetchData()">
              <a-radio-button :value="1">
                {{ $t("driverProfile.active") }}
              </a-radio-button>
              <a-radio-button :value="0">
                {{ $t("driverProfile.inactive") }}
              </a-radio-button>
            </a-radio-group>
            <a-tooltip :title="$t('driverProfile.search')">
              <a-input-search
                v-model="search"
                :placeholder="$t('driverProfile.search')"
                style="width: 200px"
              />
            </a-tooltip>
          </div>
        </div>
      </ts-panel-wrapper>
      <div class="tw-overflow-x-scroll">
        <ts-table :columns="columns" :records="resources" :loading="loading">
          <template v-slot="{ record }">
            <td>{{ record.driver_code }}</td>
            <td>{{ record.driver_name }}</td>
            <td class="tw-text-center">{{ record.sex }}</td>
            <td>{{ record.dob }}</td>
            <td>
              {{ record.phone1 }} |{{ record.phone2 }} | {{
                record.phone3
              }}
            </td>
            <td>
              Start: {{ record.contract_start_date }} | End:
              {{ record.contract_end_date }}
            </td>
            <td>{{ record.vehicle_types }}</td>
            <td>{{ record.plat_number }}</td>
            <td>{{ record.agency_name }}</td>
            <td class="tw-text-center">
              <span class="badge bg-primary" v-if="record.is_internal_driver"
                >Yes</span
              >
              <span class="badge bg-secondary" v-else>No</span>
            </td>
            <td class="tw-text-center">
              <span class="badge bg-primary" v-if="record.is_active">Yes</span>
              <span class="badge bg-secondary" v-else>No</span>
            </td>
            <td class="text-center tw-space-x-3">
              <a
                href="#"
                v-tooltip="$t('edit')"
                class="text-primary mr-2"
                @click.prevent="edit(record)"
              >
                <i class="fas fa-edit"></i>
              </a>
            </td>
          </template>
        </ts-table>
      </div>
      <div class="d-flex justify-content-end tw-p-4">
        <ts-pagination
          v-model="pagination"
          @navigate="fetchData"
        ></ts-pagination>
      </div>
    </ts-panel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "driverProfile",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("driver/driverProfile", ["resources", "pagination"]),
    search: {
      get() {
        return this.$store.state.driver.driverProfile.search;
      },
      set(newValue) {
        this.$store.commit("driver/driverProfile/SET_SEARCH", newValue);
        this.$store.commit("driver/driverProfile/RESET_CURRENT_PAGE");
      },
    },
    isActive: {
      get() {
        return this.$store.state.driver.driverProfile.isActive;
      },
      set(newValue) {
        this.$store.commit("driver/driverProfile/SET_ACTIVE", newValue);
      },
    },
    isInternalDriver: {
      get() {
        return this.$store.state.driver.driverProfile.is_internal_driver;
      },
      set(newValue) {
        this.$store.commit("driver/driverProfile/SET_IS_INTERNAL", newValue);
      },
    },
    columns() {
      return [
        { name: this.$t("driverProfile.driverCode"), sortKey: 'driver_code' },
        { name: this.$t("driverProfile.driverName"), sortKey: 'driver_name' },
        { name: this.$t("driverProfile.sex"), sortKey: 'sex' },
        { name: this.$t("driverProfile.dateOfBirth"), sortKey: 'dob' },
        { name: this.$t("driverProfile.phoneNumber") },
        { name: this.$t("driverProfile.contractStartDate"), sortKey: 'contract_start_date' },
        { name: this.$t("driverProfile.vehicleType") },
        { name: this.$t("driverProfile.platNumber") },
        { name: this.$t("driverProfile.agency") },
        {
          name: this.$t("driverProfile.internalDriver"),
          style: "text-align:center",
        },
        {
          name: this.$t("driverProfile.active"),
          style: "text-align:center",
        },
        {
          name: this.$t("actions"),
          style: "text-align:center; width: 1px;",
        },
      ];
    },
  },
  methods: {
    addNew() {
      this.$router.push({ name: "driver-profile-create" });
    },
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("driver/driverProfile/fetch", { ...attributes })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({ type: "error", text: error.message });
        });
    },
    edit(record) {
      this.$store.commit("driver/driverProfile/SET_EDIT_DATA", record);
      this.$router.push({
        name: "driver-profile-edit",
        params: { id: record.driver_id },
      });
    },
  },
  watch: {
    search: debounce(function () {
      this.fetchData();
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("driver/driverProfile/RESET_STATE");
    next();
  },
};
</script>
